import { Component, HostListener, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { MatRipple } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('leftRipple', {static: false, read: MatRipple}) leftRipple: MatRipple;
  @ViewChild('rightRipple', {static: false, read: MatRipple}) rightRipple: MatRipple;
  images = [
    {id: '../assets/1.svg'},
    {id: '../assets/2.svg'},
    {id: '../assets/3.svg'},
    {id: '../assets/4.svg'},
    {id: '../assets/5.svg'},
    {id: '../assets/6.svg'},
    {id: '../assets/7.svg'},
    {id: '../assets/8.svg'},
    {id: '../assets/9.svg'},
    {id: '../assets/10.svg'},
  ];
  leftKey = '../assets/11.svg';
  rightKey = '../assets/12.svg';
  left = [];
  right = [];
  activeItem;
  rounds = [];
  result: Result;
  gameStarted: boolean;
  gameInterval: any;
  time = 0;
  currentRound: number;
  maxRounds = 10;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.gameStarted) {
      const key = event.key;
      if (key === '1' || key === '0') {
        this.launchRipple(key);
        this.animateKey(key);
        this.select(key);
      }
    }
  }

  select(key: any) {
    this.stopTimer();
    this.currentRound++;
    let rightAnswer;
    if (key === '0') {
      rightAnswer = this.left.filter(value => value.id === this.activeItem.id).length > 0;
    } else {
      rightAnswer = this.right.filter(value => value.id === this.activeItem.id).length > 0;
    }
    this.rounds.push({right: rightAnswer, time: this.time});
    if (this.currentRound > this.maxRounds) {
      this.endGame();
      return;
    }
    this.resetAlphabet();
    this.selectRandom();
    this.startTimer();
  }

  async animateKey(key: string) {
    if (key === '0') {
      this.leftKey = '../assets/13.svg';
      await new Promise(f => setTimeout(f, 300));
      this.leftKey = '../assets/11.svg';
    } else if (key === '1') {
      this.rightKey = '../assets/14.svg';
      await new Promise(f => setTimeout(f, 300));
      this.rightKey = '../assets/12.svg';
    }
  }

  resetAlphabet() {
    this.left = [];
    this.right = [];
    const images = cloneDeep(this.images);
    this.shuffle(images);

    for (let i = 0; i < 5; i++) {
      this.left.push(images[i]);
    }

    for (let j = 5; j < 10; j++) {
      this.right.push(images[j]);
    }
  }

  selectRandom() {
    const randomNumber = Math.round(Math.random() * (this.images.length - 1));
    this.activeItem = this.images[randomNumber];
  }

  startTimer() {
    this.time = 0;
    this.gameInterval = setInterval(() => {
      this.time++;
    }, 100);
  }

  stopTimer() {
    clearInterval(this.gameInterval);
  }

  newGame() {
    this.gameStarted = true;
    this.result = undefined;
    this.rounds = [];
    this.resetAlphabet();
    this.selectRandom();
    this.currentRound = 1;
    this.startTimer();
  }

  endGame() {
    this.gameStarted = false;
    let averageTime = 0;
    this.rounds.map(round => {
      averageTime += round.time;
    });
    this.result = {
      rightAnswers: this.rounds.filter(round => round.right).length,
      averageTime: Math.round(((averageTime / this.maxRounds) / 10) * 100) / 100,
      quota: Math.round((this.rounds.filter(round => round.right).length / this.maxRounds) * 100),
    };
  }

  launchRipple(key: string) {
    if (key === '0') {
      this.leftRipple.launch({persistent: false, centered: false});
    } else {
      this.rightRipple.launch({persistent: false, centered: false});
    }
  }

  shuffle(array) {
    let currentIndex = array.length;
    let randomIndex;

    while (currentIndex !== 0) {

      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }
}

export interface Result {
  rightAnswers: number;
  averageTime: number;
  quota: number;
}
